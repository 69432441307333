import LínuEfnisgrein from "../../línuefnisgrein/LínuEfnisgrein";
import TakkiAðgerð1 from "../../takkar/takkiAðgerð1/TakkiAðgerð1";
import "../greiðsla.css";
import "../../../../almenncss/grunncss.css";
import "../../../../almenncss/villaoghleður.css";
import { useEffect, useMemo, useRef, useState } from "react";
import Smeija from "../../smeija/Smeija";
import SkráGreiðslu from "../skrágreiðslu/SkráGreiðslu";
import { fáMiðlaravillu } from "../../../../gjörníngar/ímsir";
import miðlarabón from "../../../../miðlarabón";
import SínaGreiðslu from "../sínagreiðslu/SínaGreiðslu";
import { fáDagLesmál, fáFráTilLesmál } from "../../../../gjörníngar/fáTímaLesmál";


const Greiðsla = ({
  valinGreiðsla, 
  skikkaValdriGreiðslu,
  verðVallarTexti,
  skikkaVerðVallarTexti,
  völlur,
  tími
}) => {
  const [skráGreiðslu, skikkaSkráGreiðslu] = useState(false);

  const [verð, skikkaVerði] = useState(verðVallarTexti);
  const [hleðurVerð, skikkaHleðurVerð] = useState(null);
  const [villaVerð, skikkaVillaVerð] = useState(null);
  const verðBreita = useRef(null);

  const tímasetníng = useMemo(() => {
    return {dagur: fáDagLesmál(new Date(tími[0])), tími: fáFráTilLesmál(new Date(tími[0]), new Date(tími[1]))}
  }, [])
  
  useEffect(() => {

    async function sækjaVerð() {
      skikkaHleðurVerð("Sækir verð vallar...");
      skikkaVillaVerð(null);
      try {
        const fáverð = await miðlarabón.get(`/vellir/verd/${völlur.einki}`);
        const verðVallar = fáverð.data ? fáverð.data.split("\n") : null;
        verðBreita.current = verðVallar;
        skikkaVerði(verðVallar);
      } catch (villaBónar) {
        skikkaVillaVerð(fáMiðlaravillu(villaBónar));
      } 
      skikkaHleðurVerð(null);
    }

    if (völlur && völlur.erverð && !verðVallarTexti) {
      sækjaVerð();
    } 

    return () => {
      if (verðBreita.current) {
        //til að þurfa ekki að sækja verð vallar aftur ef farið er úr smeiju og aftur inn í hana
        skikkaVerðVallarTexti(verðBreita.current);
      }
    }
  }, [])

  /*
  

  
  */
  


  return (
    <>
      {valinGreiðsla ? (
        <>
          <p className="smeija-aðaltakki">Greiðsla:</p>
          <SínaGreiðslu greiðsla={valinGreiðsla}/>
          <div className="umgjörð-lárétt tveir-takkar skrágreiðslu-tími">
            <div className="smeija-takki">
              <TakkiAðgerð1 texti="Breyta greiðslu" aðgerð={() => skikkaSkráGreiðslu(true)} />
            </div>
            <div className="smeija-takki">
              <TakkiAðgerð1 texti="Eyða greiðslu" aðgerð={() => skikkaValdriGreiðslu(null)} />
            </div>
          </div>
        </>
      ) : (
        verð && (
          <>
            <p className="smeija-takki skrágreiðslu-verð">Verð vallar:</p>
            <LínuEfnisgrein listi={verð}/>
            <div className="smeija-takki skrágreiðslu-tími">
              <TakkiAðgerð1 texti="Skrá greiðslu" aðgerð={() => skikkaSkráGreiðslu(true)} />
            </div>
          </>
        )
      )}
      {hleðurVerð && <p className="smeija-takki villaoghleður-hleður">{hleðurVerð}</p>}
      {villaVerð && <p className="smeija-takki villaoghleður-villa">{villaVerð}</p>}
      {skráGreiðslu && (
        <Smeija lokaSmeiju={skikkaSkráGreiðslu}>
          <SkráGreiðslu 
            verð={verð} 
            breitaGreiðslu={skikkaValdriGreiðslu} 
            valinGreiðsla={valinGreiðsla}
            lokaSmeiju={skikkaSkráGreiðslu}
            tímasetníng={tímasetníng}
          />
        </Smeija>
      )}
    </>


  )
}


export default Greiðsla;